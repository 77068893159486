import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useI18next} from 'gatsby-plugin-react-i18next'

import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Slide1 from '../../assets/img/main-page/slide-1.jpg'
import Slide4 from '../../assets/img/main-page/slide-4.jpg'
import Slide5 from '../../assets/img/main-page/slide-5.jpg'
import Sticks from '../../assets/img/main-page/sticks.svg'
import CityMap from '../../assets/img/main-page/city_map.jpg'
import IndusrtyPoster from '../../assets/img/main-page/industry-poster.jpg'
import Industry from '../../assets/video/plume_main.mp4'


import ArtefactCircle from '../../assets/img/main-page/artefact_circle.svg'
import ArtefactSecondMobile
    from '../../assets/img/main-page/artefact_second_mobile.svg'

import './main-page.component.less'
import Seo from "../seo";

const MainPageComponent = () => {
    const {i18n, t} = useTranslation();
    const {originalPath} = useI18next();
    const videoRef = useRef(undefined);

    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    return (
        <div className="wrapper_page">
            <Seo description={t("metaDescriptionMain")} lang={i18n.language}
                 title={t("titleMain")} />
            <div>
                <div className="main-page main-page--top main-page_main-block">
                    <div className="main-page_main-block__title">
                        <div>
                            <div className="display-none-mobile"
                                 dangerouslySetInnerHTML={{__html: t("smartAir")}}/>
                            <div className="display-block-mobile"
                                 dangerouslySetInnerHTML={{__html: t("smartAirMobile")}}/>
                        </div>
                    </div>
                    <div className="main-page_main-block__content">
                        <div className="main-page_main-block__description">
                            <div
                                className="main-page_main-block__description--top">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("cityAirIsAnExpert")}}/>
                            </div>
                            <div
                                className="main-page_main-block__description--bottom">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("toCreateOur")}}/>
                            </div>
                        </div>
                        <div className="main-page_main-block__carousel">
                            <Carousel showStatus={false} showArrows={false}
                                      showIndicators={true} interval={5000}
                                      showThumbs={false} infiniteLoop={true}
                                      autoPlay={true} stopOnHover={false}>
                                <div
                                    className="main-page_main-block__carousel--wrapper">
                                    <div
                                        className="main-page_main-block__carousel--description">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("everyMinuteProfessionalAirQuality")}}/>
                                    </div>
                                    <img alt="" src={Slide4}
                                         className="main-page_main-block__carousel--img"/>
                                </div>
                                <div
                                    className="main-page_main-block__carousel--wrapper">
                                    <div
                                        className="main-page_main-block__carousel--description">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityAirLabsInvestigate")}}/>
                                    </div>
                                    <img alt="" src={Slide1}
                                         className="main-page_main-block__carousel--img"/>
                                </div>
                                <div
                                    className="main-page_main-block__carousel--wrapper">
                                    <div
                                        className="main-page_main-block__carousel--description">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("WeAreHappyToTake")}}/>
                                    </div>
                                    <img alt="" src={Slide5}
                                         className="main-page_main-block__carousel--img"/>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className="main-page main-page_second-block">
                    <div className="main-page_second-block__main-element">
                        <div
                            className="main-page_second-block__main-element--left_block">
                            <Link to="/smart-city" language={i18n.language}>
                                <div
                                    className="main-page_second-block__main-element--left-block-title">
                                    <div
                                        className="main-page_second-block__main-element--left-block-sub-title">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("forSmartCities")}}/>
                                    </div>
                                    <div>
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityScreenEcology")}}/>
                                    </div>
                                </div>
                            </Link>
                            <div
                                className="main-page_second-block__main-element--left-block-stick display-block-mobile"/>
                            <div
                                className="main-page_second-block__main-element--left-block-img main-page_second-block__main-element--left-block-img-1">
                                <div
                                    className="main-page_second-block__main-element--left-block-stick display-none-mobile"/>
                            </div>
                            <div
                                className="main-page_second-block__absolut-element">
                                <div
                                    className="main-page_second-block__absolut-element--text-block">
                                    <div
                                        className="main-page_second-block__absolut-element--text-block-first">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityAirMayHelpSmartCities")}}/>
                                    </div>
                                    <div
                                        className="main-page_second-block__absolut-element--text-block-second">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityAirBuildsContinuousAir")}}/>
                                    </div>
                                </div>
                                <div
                                    className="main-page_second-block__absolut-element--img">
                                    <img alt=""
                                         className="main-page_second-block__absolut-element--img_inner"
                                         src={CityMap}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="main-page_second-block__main-element--right-block">
                            <div
                                className="main-page_second-block__main-element--right-block-text">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("DueToContinueDexceedance")}}/>
                            </div>
                            <Link to="/smart-city" language={i18n.language}
                                  className="main-page_second-block__main-element--right-block-btn">
                                <div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("moreInfo")}}/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="main-page main-page_second-block-2">
                    <img alt="" className="main-page__artefact-1"
                         src={ArtefactCircle}/>
                    <div
                        className="main-page_second-block__main-element white-color">
                        <div
                            className="main-page_second-block__main-element--left_block">
                            <Link to="/industry" language={i18n.language}>
                                <div
                                    className="main-page_second-block__main-element--left-block-title main-page_second-block__main-element--left-block-title-2">
                                    <div
                                        className="main-page_second-block__main-element--left-block-sub-title">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("forGreenManufacturing")}}/>
                                    </div>
                                    <div>
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityScreenIndustry")}}/>
                                    </div>
                                </div>
                            </Link>
                            <div
                                className="main-page_second-block__main-element--left-block-stick main-page_second-block__main-element--left-block-stick-red display-block-mobile"/>
                            <div
                                className="main-page_second-block__absolut-element main-page_second-block__absolut-element--2 white-color">
                                <div
                                    className="main-page_second-block__absolut-element--text-block">
                                    <div
                                        className="main-page_second-block__absolut-element--text-block-first">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityScreenIndustryEnvironmentalManagementPlatform")}}/>
                                    </div>
                                    <div
                                        className="main-page_second-block__absolut-element--text-block-second">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("cityScreenIndustryNewApproachTo")}}/>
                                    </div>
                                </div>
                                <div
                                    className="main-page_second-block__absolut-element--img main-page_second-block__absolut-element--img-2">
                                    <video loop autoPlay muted playsInline
                                           className="main-page_second-block__absolut-element--img_inner"
                                           ref={videoRef}
                                           poster={IndusrtyPoster}>
                                        <source src={Industry}
                                                type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                            <div
                                className="main-page_second-block__main-element--left-block-img main-page_second-block__main-element--left-block-img-2">
                                <div
                                    className="main-page_second-block__main-element--left-block-stick main-page_second-block__main-element--left-block-stick-red display-none-mobile"/>
                                <img alt="" className="main-page__artefact-2"
                                     src={ArtefactSecondMobile}/>
                            </div>
                            <img alt=""
                                 className="main-page_second-block__main-element--left-block-sticks"
                                 src={Sticks}/>
                        </div>
                        <div
                            className="main-page_second-block__main-element--right-block main-page_second-block__main-element--right-block-2">
                            <div
                                className="main-page_second-block__main-element--right-block-text">
                                <div
                                    className="main-page_second-block__main-element--right-block-text-first">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("cityScreenIndustryWideRangeAndComplex")}}/>
                                </div>
                            </div>
                            <Link to="/industry" language={i18n.language}
                                  className="main-page_second-block__main-element--right-block-btn main-page_second-block__main-element--right-block-btn--white">
                                <div>
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("moreInfo")}}/>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="main-page main-page_second-block-end">
                    <div className="main-page_second-block__main-element">
                        <div
                            className="main-page_second-block__main-element--left_block">
                            <Link to="/communities" language={i18n.language}>
                                <div
                                    className="main-page_second-block__main-element--left-block-title main-page_second-block__main-element--left-block-title-end">
                                    <div
                                        className="main-page_second-block__main-element--left-block-sub-title">
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("forApplicationAnd")}}/>
                                    </div>
                                    <div>
                                        <div
                                            dangerouslySetInnerHTML={{__html: t("dataServices")}}/>
                                    </div>
                                </div>
                            </Link>
                            <div
                                className="main-page_second-block__main-element--right-block-text main-page_second-block__main-element--right-block-text-1_1">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("DeployTheAirQualityMonitoring")}}/>
                            </div>
                            <div
                                className="main-page_second-block__main-element--left-block-stick main-page_second-block__main-element--left-block-stick-green display-block-mobile"/>
                            <div
                                className="main-page_second-block__main-element--left-block-img main-page_second-block__main-element--left-block-img-end">
                                <div
                                    className="main-page_second-block__main-element--left-block-stick main-page_second-block__main-element--left-block-stick-green display-none-mobile"/>
                            </div>
                        </div>
                        <div
                            className="main-page_second-block__main-element--right-block main-page_second-block__main-element--right-block-end">
                            <div
                                className="main-page_second-block__main-element--right-block-text main-page_second-block__main-element--right-block-text-1">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("DeployTheAirQualityMonitoring")}}/>
                            </div>
                            <div
                                className="main-page_second-block__main-element--right-block-text main-page_second-block__main-element--right-block-text-2">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("CityAirIsANearReferenceAir")}}/>
                            </div>
                            <Link to="/communities" language={i18n.language}
                                  className="main-page_second-block__main-element--right-block-btn main-page_second-block__main-element--right-block-btn-end">
                                <div
                                    dangerouslySetInnerHTML={{__html: t("moreInfo")}}/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MainPageComponent.propTypes =
    {}

MainPageComponent.defaultProps =
    {}

export default MainPageComponent
